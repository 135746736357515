import { useEffect, useState } from "react";
import { Login } from "./components/login/Login.js";
import { Home } from "./components/dashboard/Home.js";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute/index.js";
import { getItem } from "./util/localStorage/index.js";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import { CallList } from "./components/GPT/CallList.js";
import Header from "./common/Header.js";
import Sidebar from "./common/Sidebar.js";
import { useLocation } from "react-router";
import Footer from "./common/Footer.js";
// CSS
import "./App.css";
import "./assets/css/style.css";

import { AllLead } from "./components/leads/AllLead.js";

import { AvailibilitySchedule } from "./components/avalibility/AvailibilitySchedule.js";
import { Profile } from "./components/profile/AdminProfile.js";
import { Appointments } from "./components/appointments/appointments.js";
import { ChangePassword } from "./components/changePassword/ChangePassword.js";
import UserList from "./components/users/List.jsx";
import Configuration from "./components/configurations/Config.jsx";
import setupAxiosInterceptors from "./service/axiosConfig.js";
import KnowledgeBase from "./components/knowledgeBase/Index.jsx";
import { GlobalValues } from "./context/globalValues.js";


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (getItem("safe_tek_admin_token")) {
    } else {
      navigate("/");
    }
    setupAxiosInterceptors();
  }, []);

  return (
    <GlobalValues>
      <>
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />


          <Route
            path="/leads"
            element={
              <ProtectedRoute>
                <AllLead />
              </ProtectedRoute>}
          >
          </Route>

          <Route
            path="/availibility"
            element={
              <ProtectedRoute>
                <AvailibilitySchedule />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/appointments"
            element={
              <ProtectedRoute>
                <Appointments />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/ChangePassword"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/Admin"
            element={<ProtectedRoute>
              <Profile />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/call-list"
            element={<ProtectedRoute>
              <CallList />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/user-list"
            element={<ProtectedRoute>
              <UserList />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/configuration"
            element={<ProtectedRoute>
              <Configuration />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/knowledge-base"
            element={<ProtectedRoute>
              <KnowledgeBase />
            </ProtectedRoute>}
          > </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}

      </>
    </GlobalValues>
  );
}

export default App;
