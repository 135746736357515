import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ThreeDotSpinner } from "../loader"
import { useEffect, useState } from "react";
import { Formik, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { aiService } from "../../service/api.service";
import swal from "sweetalert";

function Configuration() {
  const [loader, setLoader] = useState(false);
  const [settings, setSettings] = useState({})
  const super_u = localStorage.getItem('super_safe')

  const [validationSchema, setValidationSchema] = useState({
    welcomeMsg: Yup.string()
      .required('Welcome message is required'),
    systemPrompt: Yup.string()
      .required('System prompt is required'),
  })

  const configValidation = Yup.object().shape(validationSchema);

  useEffect(() => {
    getConfig()
    if (super_u != 'true') {
      setValidationSchema({
        ...validationSchema,
        openAiApi: Yup.string()
          .required('Open AI API Key is required'),
        deepgramKey: Yup.string()
          .required('Deepgram Key is required'),
        twilioSid: Yup.string()
          .required('Twilio SID is required'),
        twilioSecretKey: Yup.string()
          .required('Twilio Secret Key is required'),
        twilioNumber: Yup.string()
          .required('Twilio Number is required')
          .matches(/^\+?[1-9]\d{1,14}$/, 'Twilio Number must be a valid phone number')
      })
    }
  }, [])

  async function getConfig() {
    try {
      setLoader(true)
      let sets = await aiService.configuration('GET')
      sets = sets.data.data
      setSettings(sets)
      // console.log(sets.data.data)
      for (const [key, value] of Object.entries(sets)) {
        // console.log(key, value)
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
    }
  }


  return (
    <div className="dashboard-main-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <Row className="row mb-3">
            <div className="col-8 ms-4">
              <h2 className="align-self-center m-0">Configuration</h2>
            </div>
          </Row>
          <Row className="pb-4 justify-content-center">
            <Col md={11}>
              <div className="availabilit-section input-w-100">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    openAiApi: settings.openAiApi,
                    deepgramKey: settings.deepgramKey,
                    twilioSid: settings.twilioSid,
                    twilioSecretKey: settings.twilioSecretKey,
                    twilioNumber: settings.twilioNumber,
                    welcomeMsg: settings.welcomeMsg,
                    systemPrompt: settings.systemPrompt
                  }}
                  validationSchema={configValidation}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(false);
                    try {
                      setLoader(true)
                      await aiService.configuration('post', values)
                      setLoader(false)
                      swal({ icon: "success", text: "Updated successfully." })
                    } catch (err) {
                      setLoader(false)
                    }

                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                      {
                        super_u != 'true' &&
                        <Row>
                          <Col md="4">
                            <Form.Group controlId="formOpenAiApi" className="mt-3">
                              <Form.Label>OPEN AI API</Form.Label>
                              <Form.Control
                                type="text"
                                className="w-100"
                                name="openAiApi"
                                placeholder="Enter Open AI API Key"
                                value={values.openAiApi}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.openAiApi && !!errors.openAiApi}
                              />
                              <ErrorMessage name="openAiApi" component="div" className="text-danger" />
                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group controlId="formDeepgramKey" className="mt-3">
                              <Form.Label>DEEPGRAM KEY</Form.Label>
                              <Form.Control
                                type="text"
                                className="w-100"
                                name="deepgramKey"
                                placeholder="Enter Deepgram Key"
                                value={values.deepgramKey}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.deepgramKey && !!errors.deepgramKey}
                              />
                              <ErrorMessage name="deepgramKey" component="div" className="text-danger" />
                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group controlId="formTwilioSid" className="mt-3">
                              <Form.Label>TWILIO SID</Form.Label>
                              <Form.Control
                                type="text"
                                className="w-100"
                                name="twilioSid"
                                placeholder="Enter Twilio SID"
                                value={values.twilioSid}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.twilioSid && !!errors.twilioSid}
                              />
                              <ErrorMessage name="twilioSid" component="div" className="text-danger" />
                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group controlId="formTwilioSecretKey" className="mt-3">
                              <Form.Label>TWILIO SECRET KEY</Form.Label>
                              <Form.Control
                                className="w-100"
                                type="password"
                                name="twilioSecretKey"
                                placeholder="Enter Twilio Secret Key"
                                value={values.twilioSecretKey}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.twilioSecretKey && !!errors.twilioSecretKey}
                              />
                              <ErrorMessage name="twilioSecretKey" component="div" className="text-danger" />
                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group controlId="formTwilioNumber" className="mt-3">
                              <Form.Label>TWILIO NUMBER</Form.Label>
                              <Form.Control
                                type="text"
                                className="w-100"
                                name="twilioNumber"
                                placeholder="Enter Twilio Number"
                                value={values.twilioNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.twilioNumber && !!errors.twilioNumber}
                              />
                              <ErrorMessage name="twilioNumber" component="div" className="text-danger" />
                            </Form.Group>
                          </Col>
                        </Row>
                      }

                      <Row>
                        <Col md="8">
                          <Form.Group className="mt-3">
                            <Form.Label>WELCOME MESSAGE
                              <OverlayTrigger
                                // placement="right"
                                delay={{ show: 100, hide: 400 }}
                                overlay={<Tooltip>
                                  The first message that the assistant will say.
                                </Tooltip>}
                              >
                                <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                </svg>
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                              className="w-100"
                              name="welcomeMsg"
                              placeholder="The first message that the assistant will say."
                              value={values.welcomeMsg}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.welcomeMsg && !!errors.welcomeMsg}
                            />
                            {/* <Field type="text" name="welcomeMsg" id="welcomeMsg" className="form-control" /> */}
                            <ErrorMessage name="welcomeMsg" component="div" className="text-danger" />
                          </Form.Group>
                        </Col>

                        <Col md={8}>
                          <Form.Group className="mt-3">
                            <Form.Label>SYSTEM PROMPT
                              <OverlayTrigger
                                delay={{ show: 100, hide: 400 }}
                                overlay={<Tooltip>
                                  The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant.
                                </Tooltip>}
                              >
                                <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                </svg>
                              </OverlayTrigger>

                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={10}
                              className="w-100 textarea"
                              name="systemPrompt"
                              placeholder="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant."
                              value={values.systemPrompt}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.systemPrompt && !!errors.systemPrompt}
                            />
                          </Form.Group>
                          <ErrorMessage name="systemPrompt" component="div" className="text-danger" />

                        </Col>
                      </Row>

                      <Button variant="primary" type="submit" className="mt-4" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </Form>
                    )
                  }}
                </Formik>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Configuration