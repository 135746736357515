import { useEffect, useState } from "react"
import { Table, Container, Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { aiService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

export function CallList() {
  const [calls, setCalls] = useState([])
  const [page, setPage] = useState(1)
  const [summary, setSummary] = useState("")
  const [summaryModal, setSummaryModal] = useState(false)
  const [totalItems, setTotalItems] = useState(1)
  const [callNum, setCallNum] = useState("")
  const [loader, setLoader] = useState(false);
  const page_size = 10

  useEffect(() => {
    getCallList()
  }, [page])

  async function getCallList() {
    try {
      setLoader(true)
      const params = {
        page: page,
        limit: page_size
      }
      const list = await aiService.callListGpt(params)
      setCalls(list.data.data)
      setTotalItems(list.data.total_items)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }
  }

  function handlePageChange(pageNumber) {
    setPage(pageNumber);
  }

  async function makeCall(phone) {
    try {
      if (!phone) {
        swal({ icon: "warning", text: "Kindly enter phone number." })
        return;
      }
      setLoader(true)
      await aiService.makeGptCall({ 'phone': phone })
      setLoader(false)
      swal({ icon: "success", text: "Call created successfully." })
      setPage(page)
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        swal({ icon: "error", text: error.response.data.message })
      }
    }
  }

  return (
    <div className="dashboard-main-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <Row className="row mb-3">
            <div className="col-8 ms-4">
              <h2 className="align-self-center m-0">Call List</h2>
            </div>
            <div className="col-auto d-flex">
              <Form.Control placeholder="Enter number to call. E.g. +151xxxxxxx" value={callNum} onChange={(e) => setCallNum(e.target.value)} />
              <Button variant="success" size="sm" onClick={() => makeCall(callNum)}>Call</Button>
            </div>
          </Row>
          <Row className="pb-4 justify-content-center">
            <Col md={11}>
              <div className="audit-request-box">
                <Table responsive="sm" className="UserListTable">
                  <thead>
                    <tr>
                      <th>Caller Name</th>
                      <th>Email</th>
                      <th>Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {calls.map((call, index) => (
                      <tr key={index}>
                        <td>{call.full_name}</td>
                        <td>{call.email}</td>
                        <td>{call.to_number}</td>
                        <td>
                          <Button variant="success" onClick={() => makeCall(call.to_number)}>Call back</Button>
                          {
                            call.summary && (
                              <button className="btn btn-success ms-3" onClick={() => {
                                setSummary(call.summary)
                                setSummaryModal(true)
                              }}>View Summary</button>
                            )
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={page_size}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                  prevPageText={"Prev"}
                  nextPageText={"Next"}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal show={summaryModal} onHide={() => {
        setSummaryModal(false)
        setSummary("")
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Call Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: summary }}></p>
        </Modal.Body>
      </Modal>
    </div>
  )
}