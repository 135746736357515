import { createContext, useMemo, useContext, useState } from "react";

const GlobalStates = createContext(undefined);

export const GlobalValues = ({children}) => {

    const [userDetails, setUserDetails] = useState({})

    const values = useMemo(() => (
        { userDetails, setUserDetails }
    ), 
    [userDetails]);

    return <GlobalStates.Provider value={values}>{children}</GlobalStates.Provider>
}

export const useGlobalValues = () => {
    const context = useContext(GlobalStates);
    if (context === undefined) {
      throw new Error(
        "GlobalStates must be used within a GlobalValues"
      );
    }
    return context;
};