import { Row, Col, Button, Container, Form } from "react-bootstrap"
import { useState } from "react"
import Bydefault from "../../assets/images/byDefaultUser.png"
import { ThreeDotSpinner } from "../loader"
import { useGlobalValues } from "../../context/globalValues"
export function Profile(){

    const { userDetails } = useGlobalValues();

    const [loader, setLoader]=useState(false)
    const [adminImage, setAdminImage]=useState("")
    const [adminFirstName, setAdminFirstName]=useState("")
    const [adminLastName, setAdminLastName]=useState("")
    const [adminEmail, setAdminEmail]=useState("")
    const [firstNameError, setFirstNameError]=useState("")
    const [lastNameError, setLastNameError]=useState("")
    const [showImage, setShowImage]=useState("")
    const id=localStorage.getItem("Id")

    async function updateProfile(){
        alert("update profile")
    }

    const onChangeImage=(e)=>{
       const data=e.target.files[0]
       console.log("onchangeimage...",data)
       setAdminImage(data)
       setShowImage(URL.createObjectURL(data))
    }
   
    

    const submit=(e)=>{
        e.preventDefault()
        if(adminFirstName == ""){
            setFirstNameError("Please enter first name")
        }else{
            setFirstNameError("")
        }
        if(adminLastName == ""){
            setLastNameError("Please enter last name")
        }else{
            setLastNameError("")
        }

        if(adminFirstName !== "" && adminLastName !== ""){
            updateProfile()
            console.log(">>>>>true>>")
        }else{
            console.log(">>>>>false>>")
        }
    }
    return(
        <div className="dashboard-main-area">
           {loader == true ? <ThreeDotSpinner /> : ""}
        <Container fluid>
            <div className="dashboard-area-heading management-heading">
                <Row className="row justify-content-center align-items-center mb-3">
                    <Col md={11}>
                        <Row>
                            <Col md={4} className="d-flex p-0">
                                <h2 className="align-self-center m-0">{false ? <>Edit Profile</>:<>Profile</>}</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-4 justify-content-center">
                    <Col md={11} className="commission">
                        {false == false ? 
                            <Row className="p-5">
                                <Col md={4} className="d-flex justify-content-center">
                                  {adminImage ? <img src={adminImage} className="rounded-circle" alt="image"/>:<img src={Bydefault} className="rounded-circle" alt="image" height={200} width={200}/> }
                                </Col>
                                <Col md={8}>
                                   <Row className="h-100 d-flex align-items-center">
                                      {userDetails?.name && 
                                        <Col>
                                            <span className="text-secondary">NAME</span>
                                            <h6 className="mt-1">{userDetails?.name}</h6>
                                        </Col>
                                      }
                                      <Col>
                                        <span className="text-secondary">EMAIL ADDRESS</span>
                                        <h6 className="mt-1">{userDetails?.email}</h6>
                                      </Col>
                                   </Row>
                                </Col>
                            </Row>
                            :
                            <Row className="p-4">
                                <Col>
                                   <Form>
                                     <Row>
                                        <Col md={4}>
                                            <div style={{width:"200px", height:"200px"}} className="m-auto">
                                              {adminImage ? <img src={showImage ?  showImage:adminImage} className="rounded-circle" alt="iamge" height={200} width={200}/>:<img src={Bydefault} alt="image" className="rounded-circle"  height={200} width={200}/>}
                                            </div>
                                            <input className="profileImage-select" type="file" onChange={(e)=>onChangeImage(e)}/>
                                            <span className="d-block m-auto pt-2" style={{width:"200px", textAlign:"center"}}>Upload image</span>
                                        </Col>
                                        <Col className="d-flex align-items-center">
                                           <Row>
                                             <Col>
                                               <Form.Group>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control text="text" className="editprofile" placeholder="Please enter first name" value={adminFirstName} onChange={(e)=>setAdminFirstName(e.target.value)} onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        e.preventDefault();
                                                    }
                                                }}/>
                                               </Form.Group>
                                               <span className="text-danger">{firstNameError}</span>
                                             </Col>
                                             <Col>
                                                <Form.Group>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control text="text" className="editprofile" placeholder="Please enter last name" value={adminLastName} onChange={(e)=>setAdminLastName(e.target.value)} onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        e.preventDefault();
                                                    }
                                                }}/>
                                                </Form.Group>
                                                <span className="text-danger">{lastNameError}</span>
                                             </Col>
                                             <Col md={12} className="mt-3 profilr-update">
                                                <Button onClick={(e)=>submit(e)}>
                                                    Update
                                                </Button>
                                             </Col>
                                           </Row>
                                        
                                        </Col>
                                     </Row>
                                   </Form>   
                                </Col>
                            </Row>
                         }
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
    )
}
export default Profile;