import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form

} from "react-bootstrap";
import { ThreeDotSpinner } from "../loader";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { aiService } from "../../service/api.service";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';

export const AllLead = () => {
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1)

  const [type, setType] = useState("")
  const [deactivatedReason, setDeactivatedReason] = useState("")
  const [reasonError, setReasonError] = useState("")

  const [calldata, setcallData] = useState()
  const [limit, setlimit] = useState(10)

  // const [userId, setUserId] = useState("")



  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false);  };
 

 
  useEffect(() => {
    lead_list(page, search)
  }, [])

  async function lead_list(page, search) {
    setLoader(true)
    try {
      const params = {
        page: page,
        search: search,
        limit:limit
       }
      const response = await aiService.leads(params)
      if (response?.status == 200) {
        let responseData = response.data.data
         setUserData(responseData)
        setTotalItems(response?.data?.total_count)
        console.log("?????????????list", response)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber);
    lead_list(pageNumber, search)
  }

  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    lead_list(page, search)
  }




  async function makeCall(number,id,type) {
    setLoader(true)
    try {
      let params = {
        "lead_id":id,
        "phone":number,
        "company_type":type
      }
    const response = await aiService.makeCall(params)
      if (response.status == 200) {
        setLoader(false)
        swal("Success", response?.data?.message, "success").then(() => {
          lead_list(page, search)
          setShow(false)
          setDeactivatedReason("")
          setReasonError("")

        })
     }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }

  async function callList(id) {
    setLoader(true)
    try {
       const response = await aiService.callList(id)
      if (response.status == 200) {
       
        setLoader(false)
        console.log(response)
        setcallData(response.data)
        setShow(true)

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }
  function disableUser() {
    if (deactivatedReason !== "") {
      console.log("??????????????saveeeebutton click", deactivatedReason)
      // userStatus(userId)
      setReasonError("")
      setShow(false)
    } else {
      setReasonError("Please enter the reason")

    }
  }

 

  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Leads</h2>
                  </div>
                  <div className="heading-top-area-right">
                 
                    <div className="search-area">
                      <label>Search</label>
                      <form
                        autoComplete="off"
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <input
                          type="text"
                          className="form-control inner-input"
                          onChange={(e) => handleUpdateSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          onClick={() => lead_list(page, search, type)}
                        ></i>
                        <i className="fa fa-close d-none" aria-hidden="true"></i>
                      </form>
                    </div>
                    {/* <div className="export-csv-btn">
                    <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        {/* <th>Image</th> */}
                        <th>First Name</th>
                        {/* <th>Last Name</th> */}
                        <th className="email-section">Email</th>
                        <th>Phone</th>
                        {/* <th>Source</th> */}
                        <th>Action</th>
                        <th>Key Note</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * 10 + (index + 1)}</td>
                              {/* <td>{data?.image ? <img src={apiBaseUrl + data?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td> */}
                              <td>{data?.name ? <>{data?.name}</> : <>-</>}</td>
                              {/* <td>{data?.last_name ? <>{data?.last_name}{" "}{data?.last_name}</> : <>-</>}</td> */}
                              <td className="email-section">{data?.email ? <>{data?.email}</> : <>-</>}</td>
                              <td>{data?.phone_number ? <>{data?.phone_number}</> : <>-</>}</td>
                              <td><button onClick={()=>makeCall(data?.phone_number,data._id,data.company_type)} className="btn btn-success"> Call</button></td>
                              <td>
                                {data.keynotes?
                                <button className="btn btn-success" onClick={()=>callList(data._id)}>View Keynote</button>
                              :"-"}
                             </td> 
                            </tr>
                          )
                        })) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
               
                  {userData.length > 0 && totalItems>limit?

                    <Pagination
                      activePage={page}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Call Key Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {console.log("====iiiii===",calldata)}
   
                        {calldata && calldata.map((data, index) => {
                       
                          
                          if(data.summary){
                            const format1 = "DD-MM-YYYY HH:mm:ss"
                            var duration = moment.duration(parseInt(data.call_duration), 'seconds');
                            console.log("====duration==",duration)
                            var formatted = duration.format("hh:mm:ss");
                            var date1 = new Date(data.call_time);
                            var dateTime1 = moment(date1).format(format1);
                          return (
                            <div className="keynote">
                              <p> Date&Time: <b>{dateTime1}</b></p>
                              <p className=""> Duration: <b>{formatted}</b></p>
                          <p dangerouslySetInnerHTML={{__html:data.summary}} className="call-key-text"></p>
                          </div>
                          )
                          }
                        })}
        

          </Modal.Body>
          <Modal.Footer className="pt-3">
            <Button variant="secondary" onClick={handleClose} className="close-btn">
              Close
            </Button>
            {/* <Button variant="primary" onClick={disableUser}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>




      </div>
    </>
  );
};
